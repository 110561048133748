/* flexbox */
.am-flexbox {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.am-flexbox.am-flexbox-dir-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.am-flexbox.am-flexbox-dir-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.am-flexbox.am-flexbox-dir-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.am-flexbox.am-flexbox-dir-column .am-flexbox-item {
  margin-left: 0;
}
.am-flexbox.am-flexbox-dir-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.am-flexbox.am-flexbox-dir-column-reverse .am-flexbox-item {
  margin-left: 0;
}
.am-flexbox.am-flexbox-nowrap {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.am-flexbox.am-flexbox-wrap {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.am-flexbox.am-flexbox-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
}
.am-flexbox.am-flexbox-justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.am-flexbox.am-flexbox-justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.am-flexbox.am-flexbox-justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.am-flexbox.am-flexbox-justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.am-flexbox.am-flexbox-justify-around {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
.am-flexbox.am-flexbox-align-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.am-flexbox.am-flexbox-align-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.am-flexbox.am-flexbox-align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.am-flexbox.am-flexbox-align-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.am-flexbox.am-flexbox-align-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.am-flexbox.am-flexbox-align-content-start {
  -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
}
.am-flexbox.am-flexbox-align-content-end {
  -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
          align-content: flex-end;
}
.am-flexbox.am-flexbox-align-content-center {
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.am-flexbox.am-flexbox-align-content-between {
  -webkit-align-content: space-between;
      -ms-flex-line-pack: justify;
          align-content: space-between;
}
.am-flexbox.am-flexbox-align-content-around {
  -webkit-align-content: space-around;
      -ms-flex-line-pack: distribute;
          align-content: space-around;
}
.am-flexbox.am-flexbox-align-content-stretch {
  -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
          align-content: stretch;
}
.am-flexbox .am-flexbox-item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 8px;
  min-width: 10px;
}
.am-flexbox .am-flexbox-item:first-child {
  margin-left: 0;
}
